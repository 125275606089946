// App.jsx
import React, { useEffect, useState } from "react";
import { Flex, ThemeProvider, View } from "@aws-amplify/ui-react";
import { LoaderColor } from "./components/LoaderColor.jsx";
import LoginForm from "./components/LoginForm";
import ResetPassword from "./components/ResetPasswordForm.jsx";
import LoggedIn from "./components/LoggedIn";
import { checkSession } from "./utils/checkSession";
import { useRoutes } from "react-router-dom";
import "./App.css";
import { viseeonTheme } from "./theme";

const App = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [redirectLoginUrlWithParams, setRedirectLoginUrlWithParams] = useState(null);

  useEffect(() => {
    handleIsLogged();
    //  if the page is opened in an iframe, redirect to the parent window, but only if the redirectLoginUrlWithParams is set with ID for intranet
    /* if (
      window.top !== window.self &&
      (redirectLoginUrlWithParams?.id === "6e45ui9ubcogik19htbr9ovipn" ||
      redirectLoginUrlWithParams?.id === "2d9hqrejlffigq6ng2uvvfgm50")
    ) {
      console.log("redirecting to parent window");
      console.log('window.location.href', window.location.href)
      console.log('window.top.location', window.top.location)
      // window.top.location = window.location.href;
      window.top.location.replace(window.location.href);
      // window.top.location = `http://localhost:3001/login?client_id=6e45ui9ubcogik19htbr9ovipn&response_type=token&scope=email+openid&redirect_uri=https://fr.ultron.viseeon.io/api/v1/auth/callback`  
    } */
  }, [/* redirectLoginUrlWithParams */]);

  const handleIsLogged = async () => {
    setIsLoading(true);
    const result = await checkSession();
    setIsLoading(false);
    if (result) {
      setRedirectLoginUrlWithParams(result.data);
    }
  };

  let content = useRoutes([
    {
      path: "/login",
      element: redirectLoginUrlWithParams ? (
        <LoggedIn redirectLoginUrlWithParams={redirectLoginUrlWithParams} />
      ) : (
        <LoginForm isLoading={isLoading} />
      ),
    },
    {
      path: "/reset-password",
      element: <ResetPassword />,
    },
  ]);

  return (
    <ThemeProvider theme={viseeonTheme}>
      {isLoading && <LoaderColor />}
      <Flex
        paddingTop="150px"
        direction="column"
        justifyContent="space-around"
        alignItems="center"
        alignContent="flex-start"
        wrap="nowrap"
      >

        <View>{content}</View>
      </Flex>
    </ThemeProvider>
  );
};

export default App;
